<template>
  <StatisticSellerComponent />
</template>


<script>

import StatisticSellerComponent from "@/components/statistic/statisticSellerComponent.vue";

export default {
  name: "StatisticSellerView",
  components: {
    StatisticSellerComponent,
  },
};
</script>