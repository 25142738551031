<template>
  <div class="mb-2">
    <v-progress-linear
        v-if="loading"
        indeterminate
        color="orange"
        absolute
        top
    ></v-progress-linear>

    <v-card outlined color="transparent">
      <v-card-text class="pa-0">
        <v-card>
          <v-card-title>
            <v-row no-gutters justify="space-between" align="center" class="mx-4">
              <v-col cols="12">
                {{ $t("sideBar.statistic_seller") }}
              </v-col>
            </v-row>
          </v-card-title>

          <v-tabs
              v-model="selectedTab"
              :background-color="$vuetify.theme.dark ? 'grey darken-2' : 'white lighten-1'"
              class="mb-5"
              show-arrows
              grow
          >
            <v-tab
                v-for="(tab, index) in tabs"
                :key="tab.value"
                @click="fetchStatistic(tab)"
            >
              {{ tab.text }}
            </v-tab>
          </v-tabs>

          <v-row class="px-4">
            <time-picker @callTheFuction="addFilter"/>
            <utm-order-picker v-if="selectedTab === 6" :utmsList="utmsList" @setValues="setValues"/>
            <utm-picker @setFilters="setFilters" @refreshFilter="refreshFilter" :UTMs="utmFilteredProps"/>
          </v-row>

          <v-card-text class="pb-1" v-if="selectedTab !== 6">
            <v-data-table
                :headers="statisticTrafficHeaders"
                :items="statisticListTraffic"
                :items-per-page="statisticListTraffic.length"
                :height="getTableHeight"
                class="fixed-column-table"
                item-key="id"
                dense
                outlined
                hide-default-footer
                v-model:expanded="expandedItems"
                :sort-by="firstColumn"
                :class="{
            'dark-theme': $vuetify.theme.dark,
            'light-theme': !$vuetify.theme.dark,
            'highlight-multiple-columns': tab.value === 'utms'
          }"
            >
              <template v-slot:item.CR="{ item }">
                {{ item.CR }} {{ '%' }}
              </template>
              <template v-slot:item.approve="{ item }">
                {{ item.approve }} {{ '%' }}
              </template>
              <template v-slot:item.purchased_percent="{ item }">
                {{ item.purchased_percent }} {{ '%' }}
              </template>
              <template v-slot:item.advertorial_conversion="{ item }">
                {{ item.advertorial_conversion }} {{ '%' }}
              </template>
              <template v-slot:item.advertorial_conversion_through="{ item }">
                {{ item.advertorial_conversion_through }} {{ '%' }}
              </template>
              <template v-slot:item.product_page_conversion="{ item }">
                {{ item.product_page_conversion }} {{ '%' }}
              </template>
              <template v-slot:item.product_page_conversion_through="{ item }">
                {{ item.product_page_conversion_through }} {{ '%' }}
              </template>
              <!--              <template v-slot:[`body.append`]>-->
              <!--                <tr>-->
              <!--                  <td :colspan="23">-->
              <!--                    <v-progress-linear-->
              <!--                        v-if="isLoadingItems"-->
              <!--                        indeterminate-->
              <!--                        color="primary"-->
              <!--                    ></v-progress-linear>-->
              <!--                    <div-->
              <!--                        v-observer="{ nextPage: nextPage }"-->
              <!--                        class="observer"-->
              <!--                        v-if="!loadMoreDisabled && !isLoadingItems"-->
              <!--                    ></div>-->
              <!--                  </td>-->
              <!--                </tr>-->
              <!--              </template>-->
            </v-data-table>
          </v-card-text>
          <v-card-text class="pb-1" v-else>
            <v-data-table
                :headers="statisticTrafficHeaders"
                :items="statisticListUtmTraffic"
                item-key="utm_key"
                show-expand
                class="elevation-1"
                hide-default-footer
                :sort-by="firstColumn"
            >
              <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
                <v-icon
                    @click="
            expand(!isExpanded);
            loadChildren(item, expand, isExpanded);
          "
                >
                  {{ isExpanded ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon>
              </template>
              <template v-slot:[`item.utm_key`]="{ item }">
                {{ item.id }} {{ item.utm_key }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <nested-table v-if="item.children && item.children.length" :items="item.children" :filterProps="filterProps" :utmFilteredProps="utmFilteredProps"/>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import notifications from "@/mixins/notifications";
import DeleteConfirmDialog from "@/components/dialog/deleteConfirmDialog.vue";
import AddEditOrderDialog from "@/components/orders/addEditOrderDialog.vue";
import statisticService from "@/services/request/statistic/statisticService";
import TimePicker from "@/components/UI/timePicker.vue";
import dataTableMixin from "@/mixins/dataTableMixin";
import loader from "@/mixins/loader";
import UtmPicker from "@/components/UI/utmPicker.vue";
import UtmOrderPicker from "@/components/UI/utmOrderPicker.vue";
import NestedTable from "@/components/UI/NestedTable.vue";
import * as getterTypes from "@/store/modules/utmOrderValues/types/getters";
import statisticSellerMixin from "@/mixins/statisticSellerMixin";

export default {
  components: {NestedTable, UtmOrderPicker, UtmPicker, TimePicker, AddEditOrderDialog, DeleteConfirmDialog},
  mixins: [notifications, dataTableMixin, loader, statisticSellerMixin],
  data: () => ({
    loading: false,
    statisticListSell: [],
    statisticListTraffic: [],
    statisticListUtmTraffic: [],
    offsetBottom: 285,
    filterProps: {},
    utmFilteredProps: {},
    expandedItems: [],
    selectedTab: 0,
    tab: {
      value: 'dates'
    }
  }),
  computed: {
    ...mapGetters("utmOrderValues", {
      utmsList: getterTypes.GET_UTMS_LIST
    }),
    firstColumn() {
      return this.statisticTrafficHeaders.length > 0 ? this.statisticTrafficHeaders[0].value : null;
    }
  },
  methods: {
    async loadChildren(item) {
      if (item.children.length || item.loading) return;

      item.loading = true;
      try {
        const params = {
          start_date: this.filterProps.date_created_begin || this.filterProps.time_created,
          end_date: this.filterProps.date_created_end || this.filterProps.time_created,
          ...this.utmFilteredProps
        };

        const formData = new FormData();
        this.utmsList.slice(0, 2).forEach((utm, index) => {
          formData.append(`utms[${index}]`, utm.label);
          if (item[utm.label]) {
            formData.append(utm.label, item[utm.label]);
          }
        });

        const response = await statisticService.getStatisticUtmTraffic(params, formData);

        this.$set(item, "children", response.map(child => ({...child, children: []})));
      } catch (error) {
        console.error("Помилка завантаження дітей:", error);
      } finally {
        item.loading = false;
      }
    },
    async setValues() {
      await this.fetchStatisticTraffic(1);
    },
    addFilter(props) {
      console.log(props)
      this.filterProps = props;
      this.fetchStatisticTraffic(1);
    },
    setFilters(props) {
      console.log(props)
      this.utmFilteredProps = props;
      this.fetchStatisticTraffic(1);
    },
    refreshFilter(props) {
      this.utmFilteredProps = props;
      this.fetchStatisticTraffic(1);
    },
    async fetchStatisticTraffic(page) {
      this.isLoadingItems = true;
      try {
        const params = {
          start_date: this.filterProps.date_created_begin || this.filterProps.time_created,
          end_date: this.filterProps.date_created_end || this.filterProps.time_created,
          ...this.utmFilteredProps,
        };

        const formData = new FormData();
        if (this.tab.value === "utms") {
          formData.append("utms[0]", this.utmsList[0].label);
        }

        const response = this.tab.value === "utms"
            ? await statisticService.getStatisticUtmTraffic(params, formData)
            : await statisticService.getStatisticTrafficSeller(params, this.tab.value);

        const processedResponse = response.map(item => ({...item, children: []}));
        let trafficList = this.tab.value === "utms" ? this.statisticListUtmTraffic : this.statisticListTraffic;

        if (response && response.length > 0) {
          if (page > 1) {
            trafficList = [...trafficList, ...processedResponse];
          } else {
            trafficList = processedResponse;
          }
        } else {
          trafficList = processedResponse;
        }
        this.tab.value !== "utms" ? this.statisticListTraffic = trafficList : this.statisticListUtmTraffic = trafficList;

        this.loadMoreDisabled = response.length < this.perPage;
      } catch (e) {
        console.error("Помилка при завантаженні статистики:", e);
      } finally {
        this.isLoadingItems = false;
      }
    },
    fetchStatistic(tab) {
      this.tab = tab;
      this.currentPage = 1;
      this.utmFilteredProps = {};
      this.fetchStatisticTraffic(1);
    },
    async nextPage() {
      this.currentPage += 1;
      this.isLoadingItems = true;
      await this.fetchStatisticTraffic(this.currentPage);
      this.isLoadingItems = false;
    },
  },
};
</script>

<style>
.fixed-column-table.dark-theme th:first-child,
.fixed-column-table.dark-theme td:first-child {
  position: sticky;
  left: 0;
  z-index: 3;
  background: #424242;
  color: #fff;
}

.fixed-column-table.light-theme th:first-child,
.fixed-column-table.light-theme td:first-child {
  position: sticky;
  left: 0;
  z-index: 3;
  background: white;
  color: #000;
}

.fixed-column-table.highlight-multiple-columns.dark-theme th:nth-child(1),
.fixed-column-table.highlight-multiple-columns.dark-theme td:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 3;
  background: #424242;
  color: #fff;
}

.fixed-column-table.highlight-multiple-columns.dark-theme th:nth-child(2),
.fixed-column-table.highlight-multiple-columns.dark-theme td:nth-child(2) {
  position: sticky;
  left: 65px;
  z-index: 3;
  background: #424242;
  color: #fff;
}

.fixed-column-table.highlight-multiple-columns.dark-theme th:nth-child(3),
.fixed-column-table.highlight-multiple-columns.dark-theme td:nth-child(3) {
  position: sticky;
  left: 130px;
  z-index: 3;
  background: #424242;
  color: #fff;
}

.fixed-column-table.highlight-multiple-columns.dark-theme th:nth-child(4),
.fixed-column-table.highlight-multiple-columns.dark-theme td:nth-child(4) {
  position: sticky;
  left: 215px;
  z-index: 3;
  background: #424242;
  color: #fff;
}

.fixed-column-table.highlight-multiple-columns.dark-theme th:nth-child(5),
.fixed-column-table.highlight-multiple-columns.dark-theme td:nth-child(5) {
  position: sticky;
  left: 280px;
  z-index: 3;
  background: #424242;
  color: #fff;
}

.fixed-column-table.highlight-multiple-columns.light-theme th:nth-child(1),
.fixed-column-table.highlight-multiple-columns.light-theme td:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 3;
  background: white;
  color: #000;
}

.fixed-column-table.highlight-multiple-columns.light-theme th:nth-child(2),
.fixed-column-table.highlight-multiple-columns.light-theme td:nth-child(2) {
  position: sticky;
  left: 65px;
  z-index: 3;
  background: white;
  color: #000;
}

.fixed-column-table.highlight-multiple-columns.light-theme th:nth-child(3),
.fixed-column-table.highlight-multiple-columns.light-theme td:nth-child(3) {
  position: sticky;
  left: 130px;
  z-index: 3;
  background: white;
  color: #000;
}

.fixed-column-table.highlight-multiple-columns.light-theme th:nth-child(4),
.fixed-column-table.highlight-multiple-columns.light-theme td:nth-child(4) {
  position: sticky;
  left: 215px;
  z-index: 3;
  background: white;
  color: #000;
}

.fixed-column-table.highlight-multiple-columns.light-theme th:nth-child(5),
.fixed-column-table.highlight-multiple-columns.light-theme td:nth-child(5) {
  position: sticky;
  left: 280px;
  z-index: 3;
  background: white;
  color: #000;
}
</style>
