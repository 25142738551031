<template>
  <v-data-table
      v-if="items.length"
      :headers="statisticTrafficHeaders"
      :items="items"
      item-key="utm_key"
      show-expand
      dense
      hide-default-footer
      class="ml-2"
  >
    <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
      <v-icon
          v-if="item.utms_count !== 5"
          @click="
          expand(!isExpanded);
          loadChildren(item, expand, isExpanded);
        "
      >
        {{ isExpanded ? "mdi-minus" : "mdi-plus" }}
      </v-icon>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="pl-0">
        <nested-table
            v-if="item.children && item.children.length"
            :items="item.children"
            :filterProps="filterProps"
            :utmFilteredProps="utmFilteredProps"
        />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import statisticService from "@/services/request/statistic/statisticService";
import {mapGetters} from "vuex";
import * as getterTypes from "@/store/modules/utmOrderValues/types/getters";

export default {
  name: "NestedTable",
  props: {
    items: {
      type: Array,
      required: true,
    },
    filterProps: {
      type: Object,
      required: true,
    },
    utmFilteredProps: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async loadChildren(item, expand, isExpanded) {
      if (item.children.length || item.loading) return;

      item.loading = true;
      try {
        const params = {
          start_date: this.filterProps.date_created_begin || this.filterProps.time_created,
          end_date: this.filterProps.date_created_end || this.filterProps.time_created,
          ...this.utmFilteredProps
        };

        const formData = new FormData();
        this.utmsList.forEach((utm, index) => {
          if (index <= item.utms_count) {
            formData.append(`utms[${index}]`, utm.label);
            if (item[utm.label]) {
              formData.append(utm.label, item[utm.label]);
            }
          }
        });

        const response = await statisticService.getStatisticUtmTraffic(params, formData);
        const processedResponse = response.map(child => ({ ...child, children: item.utms_count !== 4 ? [] : child }));

        this.$set(item, "children", processedResponse);
      } catch (error) {
        console.error("Помилка завантаження items:", error);
      } finally {
        item.loading = false;
      }
    }
  },
  computed: {
    ...mapGetters("utmOrderValues", {
      utmsList: getterTypes.GET_UTMS_LIST
    }),
    statisticTrafficHeaders() {
                const baseHeaders = [
              { value: 'advertorial_clicks', width: '100px', fixed: true, sortable: false,},
              { value: 'advertorial_conversion', width: '100px', fixed: true, sortable: false },
              { value: 'advertorial_conversion_through', width: '100px', fixed: true, sortable: false },
              { value: 'total_clicks', width: '100px', fixed: true, sortable: false },
              { value: 'unique_clicks', width: '100px', fixed: true, sortable: false },
              { value: 'total_orders', width: '100px', fixed: true, sortable: false },
              { value: 'CR', width: '100px', fixed: true, sortable: false },
              { value: 'in_progress_count', width: '100px', fixed: true, sortable: false },
              { value: 'rejected_count', width: '100px', fixed: true, sortable: false },
              { value: 'confirmed_count', width: '100px', fixed: true, sortable: false },
              { value: 'confirmed_total_cost', width: '100px', fixed: true, sortable: false },
              { value: 'approve', width: '100px', fixed: true, sortable: false },
              { value: 'not_purchased_count', width: '100px', fixed: true, sortable: false },
              { value: 'not_purchased_total_cost', width: '100px', fixed: true, sortable: false },
              { value: 'shipped_count', width: '100px', fixed: true, sortable: false },
              { value: 'shipped_total_cost', width: '100px', fixed: true, sortable: false },
              { value: 'purchased_count', width: '100px', fixed: true, sortable: false },
              { value: 'purchased_total_cost', width: '100px', fixed: true, sortable: false },
              { value: 'average_cheque', width: '100px', fixed: true, sortable: false },
              { value: 'purchased_percent', width: '100px', fixed: true, sortable: false },
              { value: 'arbitrageur_payout', width: '100px', fixed: true, sortable: false },
              { value: 'seller_total_buying_cost', width: '100px', fixed: true, sortable: false },
              { value: 'gross_profit', width: '100px', fixed: true, sortable: false }
          ];

          const utmHeaders = [
              { value: 'utm_parametr', width: '200px', fixed: true, sortable: false }
          ];

          const additionalHeaders = [
              { value: 'additional_1', width: '100px', fixed: true, sortable: false },
              { value: 'additional_2', width: '100px', fixed: true, sortable: false },
              { value: 'additional_3', width: '100px', fixed: true, sortable: false },
              { value: 'additional_4', width: '100px', fixed: true, sortable: false },
              { value: 'additional_5', width: '100px', fixed: true, sortable: false }
          ];

          return [...utmHeaders, ...baseHeaders, ...additionalHeaders];
        },
  }
};
</script>

<style scoped>
</style>
  