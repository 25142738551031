import { mapGetters } from "vuex";

export default {
    methods: {
        getColWidth(columnName) {
            const columnWidths = {
                landings_name: '500px',
            };
            return columnWidths[columnName] || '150px';
        },
        getSectionSuffix() {
            const suffixes = {
                dates: this.$t('statistic.by_date'),
                users: this.$t('statistic.by_user'),
                offers: this.$t('statistic.by_product'),
                streams: this.$t('statistic.by_stream'),
                landings: this.$t('statistic.by_landing'),
                advertorials: this.$t('statistic.by_advertorial'),
                prices: this.$t('statistic.by_price'),
                utms: this.$t('statistic.by_utm')
            };
            return suffixes[this.tab.value] || '';
        },
        getSectionValue() {
            const values = {
                dates: 'date',
                users: 'users_name',
                offers: 'offers_name',
                streams: 'streams_name',
                landings: 'landings_name',
                advertorials: 'advertorials_name',
                prices: 'price',
                utms: 'utm_key'
            };
            return values[this.tab.value] || '';
        },
    },
    computed: {
        ...mapGetters(["currentLanguage"]),
        statisticTrafficHeaders() {
            const baseHeaders = [
                {
                    text: this.$t('statistic.section') + ' ' + this.getSectionSuffix(),
                    value: this.getSectionValue(),
                    fixed: true,
                    width: this.getColWidth(this.getSectionValue()),
                    sortable: false
                },
                { text: this.$t('statistic.visit_advertorial'), value: 'advertorial_clicks', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.conversion_from_advertorial'), value: 'advertorial_conversion', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.cross_conversion_advertorial'), value: 'advertorial_conversion_through', width: '100px', fixed: true, sortable: false },
                {text: this.$t('statistic.visit_product_page_without_form'), value: 'product_page_clicks',  sortable: false},
                {text: this.$t('statistic.conversion_from_product_page_without_form'), value: 'product_page_conversion',  sortable: false},
                {text: this.$t('statistic.cross_conversion_product_page_without_form'), value: 'product_page_conversion_through',  sortable: false},
                { text: this.$t('statistic.visit_landing'), value: 'landing_clicks', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.unique_visitors'), value: 'unique_clicks', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.applications'), value: 'total_orders', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.site_conversion'), value: 'CR', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.in_process_at_cc'), value: 'in_progress_count', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.rejected'), value: 'rejected_count', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.confirmed'), value: 'confirmed_count', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.confirmed_sum'), value: 'confirmed_total_cost', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.approve_percentage'), value: 'approve', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.not_purchased'), value: 'not_purchased_count', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.not_purchased_sum'), value: 'not_purchased_total_cost', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.awaiting_purchase'), value: 'shipped_count', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.awaiting_purchase_sum'), value: 'shipped_total_cost', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.purchased'), value: 'purchased_count', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.purchased_sum'), value: 'purchased_total_cost', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.average_bill'), value: 'average_cheque', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.purchase_percentage'), value: 'purchased_percent', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.seller_payment'), value: 'arbitrageur_payout', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.cost_price'), value: 'seller_total_buying_cost', width: '100px', fixed: true, sortable: false },
                { text: this.$t('statistic.gross_profit'), value: 'gross_profit', width: '100px', fixed: true, sortable: false },
            ];

            const utmHeaders = [
                { text: 'UTM parameter', value: 'utm_parametr', width: '200px', fixed: true, sortable: false }
            ];

            const additionalHeaders = [
                { text: 'Additional 1', value: 'additional_1', width: '100px', fixed: true, sortable: false },
                { text: 'Additional 2', value: 'additional_2', width: '100px', fixed: true, sortable: false },
                { text: 'Additional 3', value: 'additional_3', width: '100px', fixed: true, sortable: false },
                { text: 'Additional 4', value: 'additional_4', width: '100px', fixed: true, sortable: false },
                { text: 'Additional 5', value: 'additional_5', width: '100px', fixed: true, sortable: false }
            ];

            const baseHeadersWithoutSection = baseHeaders.slice(1);

            return this.tab.value === 'utms' ? [...utmHeaders, ...baseHeadersWithoutSection, ...additionalHeaders] : baseHeaders;
        },
        tabs() {
            return [
                { text: this.$t('statistic.by_date'), value: 'dates' },
                { text: this.$t('statistic.by_user'), value: 'users' },
                { text: this.$t('statistic.by_product'), value: 'offers' },
                { text: this.$t('statistic.by_stream'), value: 'streams' },
                { text: this.$t('statistic.by_landing'), value: 'landings' },
                { text: this.$t('statistic.by_price'), value: 'prices' },
                { text: this.$t('statistic.by_utm'), value: 'utms' },
            ];
        },
    }
}