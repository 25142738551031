<template>
  <v-expand-transition>
    <v-menu :close-on-content-click="false"
            :offset-y="true"
            :offset-x="true"
            transition="slide-x-transition"
            min-width="470"
            max-height="500"
            v-model="menuOpen"
            content-class="custom-menu"
    >
      <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="px-md-2 mx-1"
                :small="$vuetify.breakpoint.mobile"
                v-bind="attrs"
                v-on="on"
                icon
                color="primary"
            >
              <v-icon>{{ menuOpen ? 'mdi-cog' : 'mdi-cog-outline' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('statistic.filters') }}</span>
        </v-tooltip>
        </span>
      </template>
      <v-row>
        <v-col class="pb-0">
            <v-card >
              <v-card-title>
                {{ 'Порядок utm міток' }}
                <v-btn icon small color="primary" class="ml-3" @click="resetUtms">
                  <v-icon small>mdi-refresh</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <draggable v-model="items" @end="onDragEnd" tag="div" class="drag-list">
                <v-row v-for="(item, index) in items" :key="item.id" :style="{ marginLeft: index >= 1 ? `${index * 25}px` : '0px' }" class="draggable-item"  :class="{ 'dark-theme': $vuetify.theme.dark }">
                  <v-icon class="drag-handle pr-4">mdi-dots-grid</v-icon>
                  {{ item.label }}
                </v-row>
                </draggable>
              </v-card-text>
            </v-card>
        </v-col>
      </v-row>
    </v-menu>
  </v-expand-transition>
</template>

<script>
import { validationMixin } from "vuelidate";
import store from "@/store";
import * as actionTypes from "@/store/modules/utmOrderValues/types/actions";
export default {
  data: () => ({
    menuOpen: false,
    inputItems: {},
    copyItems: {},
    changed: false,
    items: []
  }),
  props: {
    utmsList: {
      required: false,
      type: Array
    }
  },
  mounted() {
    this.items = this.utmsList;
  },
  methods: {
    onDragEnd() {
      store.dispatch(`utmOrderValues/${actionTypes.SET_UTMS_LIST}`, this.items);
    },
    resetUtms() {
      this.items = [
        { id: 0, label: 'utm_source' },
        { id: 1, label: 'utm_medium' },
        { id: 2, label: 'utm_campaign' },
        { id: 3, label: 'utm_content' },
        { id: 4, label: 'utm_term' }
      ];
      store.dispatch(`utmOrderValues/${actionTypes.SET_UTMS_LIST}`, this.items);
    }
  },
  mixins: [validationMixin],
  watch: {
    menuOpen: {
      deep: true,
      handler(e) {
        if(e === false) {
          this.$emit("setValues")
        }
      },
    },
  },
};
</script>

<style scoped>
.drag-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.draggable-item {
  padding: 10px;
  cursor: grab;
  background-color: #f4f4f4;
  border-radius: 8px;
}

.dark-theme {
  background-color: #424242 !important;
  color: white;
}

.drag-handle {
  cursor: grab;
  float: right;
}

.custom-menu {
  min-height: 316px !important;
}
</style>
